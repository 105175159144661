.homeCnt {
    background-color: white;
}

header {
    margin-bottom: 17px;
}

.bnrCnt {
    padding-bottom: 45px;
    padding: 0;
    /* min-height: 70vh; */
    height: 580px;
}
.bnrCnt .bnrImg {
    width: 100%;
    margin: auto;
    /* border-radius: 40px; */
    /* box-shadow: 0 3px 50px #f1f1f1; */
    height: 100%;
    max-height: 580px;
    object-fit: cover;
}

.bnrCnt .bnrImg.cnsImage {
    object-fit: contain;
}

.bnrCnt .slick-slider,
.bnrCnt .slick-slide div,
.bnrCnt .slick-track,
.bnrCnt .slick-list {
    height: 100%;
}

.bnrCnt .slick-list .slick-track .slick-slide > div {
    padding-right: 0px;
    transition: ease-in-out 0.5s;
}

.bnrCnt .slick-list .slick-track .slick-slide.slick-current > div {
    padding-right: 10px;
}

.homeCnt .bnrCnt .slick-prev {
    left: 18px;
    z-index: 1;
}

.homeCnt .bnrCnt .slick-next {
    right: 18px;
    z-index: 1;
}

.homeCnt .bnrCnt .slick-next,
.homeCnt .bnrCnt .slick-prev {
    width: 40px;
    height: 40px;
}

.wlecomeNt .lftCntnr {
    background: #293345;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 23%;
    padding-right: 80px;
    max-height: 580px;
}

.wlecomeNt h2 {
    font-size: 40px;
    font-family: var(--fontYeseva);
    font-weight: 400;
    text-align: left;
    margin: 0px;
    letter-spacing: 0px;
    line-height: 60px;
    text-transform: capitalize;
}

.wlecomeNt p {
    font-size: 24px;
    text-align: left;
    color: #24c0eb;
    margin-top: 28px;
}

.homeCnt {
    overflow-x: hidden;
}

.homeCnt .primButton {
    height: 58px;
    width: 176px;
    margin-top: 15px;
}

.homeCnt .wlecomeNt .primButton button {
    border-radius: 0px;
    background: transparent;
    border: 1px solid white;
}

.homeCnt .primButton .MuiButton-label {
    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    text-transform: capitalize;
}

.hiwGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.hiwGrid .hiwCard {
    padding: 40px 20px;
    margin-top: 30px;
}

.hiwGrid .hiwCard img {
    width: 80px;
    height: 60px;
    object-fit: contain;
}

.hiwGrid .hiwCard h5 {
    font-size: 29px;
    text-transform: uppercase;
    margin-top: 25px;
}

.tstmnlCntnr a.vwAllBtn {
    color: #187ae8;
    text-decoration: underline;
    margin-left: auto;
    font-size: 18px;
}

.homeCnt .slick-next,
.homeCnt .slick-prev {
    width: 40px;
    height: 50px;
}

.homeCnt .slick-next {
    right: -76px;
}

.homeCnt .slick-prev {
    left: -76px;
}

.hmActnSlider .slick-slide > div {
    padding: 10px;
}

.catHeading {
    font-size: 30px;
    font-family: var(--fontFamily2);
    font-weight: 600;
    color: var(--secColor);
}
.categoriesCnt .slick-slide img {
    display: inline-flex;
    object-fit: scale-down;
    /* background: #efefef; */
    border-radius: 4px;
}
.categoriesCnt .slick-next:before {
    color: var(--secColor);
}
.categoriesCnt .slick-prev:before {
    color: var(--secColor);
}
.auctionHeading {
    font-size: 35px;
    font-family: var(--fontFamily2);
    font-weight: 500;
    color: black;
    padding-bottom: 30px;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.auctionHeading::after {
    content: '';
    width: 108px;
    height: 3px;
    background: var(--secColor);
    position: absolute;
    bottom: 15px;
    left: calc(50% - 54px);
}

.orStfWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 15px;
    row-gap: 50px;
}

.orStfWrpr .ourSfCard img {
    border-radius: 260px;
    border: 14px solid var(--secColor);
    width: 230px;
    height: 230px;
    box-shadow: 0 0 10px #00000062;
}
.orStfWrpr .ourSfCard h4 {
    color: var(--secColor);
    font-size: 22px;
    margin-top: 21px;
    font-weight: 600;
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;
}

.orStfWrpr.mtp20 {
    margin-top: 50px;
}

.mtp120 {
    margin-top: 120px;
}

.imgGlryWrpr h4 {
    font-size: 36px;
}

.imgGlryWrpr .slick-slide img {
    width: 100%;
    height: 330px;
    object-fit: cover;
}

.imgGlryWrpr .slick-slide {
    display: grid;
    gap: 14px;
}

.imgGlryWrpr .slick-slide > div > div {
    padding: 0 10px;
}

.tstmnlCntnr {
    margin-top: 25px;
}

.btmCtctUsWrpr {
    display: flex;
    align-items: center;
    justify-content: center;
    background: whitesmoke;
    padding: 30px 0px;
    position: relative;
}

.btmCtctUsWrpr::after {
    content: '';
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 0;
    left: 48%;
    position: absolute;
}

.btmCtctUsWrpr p {
    margin-bottom: 0px;
    margin-right: 25px;
    font-size: 19px;
}

.btmCtctUsWrpr .primButton {
    margin-top: 0px;
}

.btmCtctUsWrpr .primButton button {
    border-radius: 100px;
    height: 60px;
}

.btmCtctUsWrpr .primButton button .MuiButton-label {
    font-size: 14px;
    text-transform: uppercase;
}

.upcomingAuction {
    padding-top: 45px;
    padding-bottom: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}
.viewmore {
    padding-bottom: 25px;
}
.viewmore a {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
}
.viewmore a:hover {
    text-decoration: none;
    outline: none;
}
.callUsLink {
    border: 1px solid white;
    transition: 200ms all ease-in-out;
    border-radius: 0px;
    background-color: transparent;
    height: 60px;
    width: 180px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
}

.callUsLink a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
}

.howitsWrk .grdContr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 35px;
}

.howitsWrk .grdContr .material-icons {
    color: gray;
    font-size: 28px;
    animation: bounce2 2s infinite;
    -webkit-animation: bounce2 2s infinite;
    -moz-animation: bounce2 2s infinite;
    -o-animation: bounce2 2s infinite;
}

/* .howitsWrk .grdContr .Hiw1 {
    width: 100%;
} */

.howitsWrk .grdContr .Hiw1 img {
    width: 100%;
    height: 320px;
    object-fit: contain;
    padding-left: 0;
}

.howitsWrk .grdContr .Hiw1 .hiw1Txt {
    width: 100%;
    padding-left: 0;
    margin-top: 35px;
}

.howitsWrk .grdContr .Hiw1 .hiw1Txt p {
    font-size: 18px;
    padding-left: 0;
}

.homeCnt .vrtlSldr {
    margin: auto;
}

.homeCnt .vrtlSldr .slick-next,
.homeCnt .vrtlSldr .slick-prev {
    top: -30px;
    width: 20px;
    height: 20px;
}

.homeCnt .vrtlSldr .slick-prev {
    left: unset;
    right: 28px;
}

.homeCnt .vrtlSldr .slick-next {
    right: 0;
}

.homeCnt .vrtlSldr .slick-next:before,
.homeCnt .vrtlSldr .slick-prev:before {
    color: var(--primColor);
}

.homeCnt .vwAllBtn {
    color: var(--primColor);
}

.homeCnt .lftpdWrp {
    padding-right: 75px;
}

@keyframes bounce2 {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@media (max-width: 1450px) {
    .homeCnt .slick-next {
        right: -45px;
    }

    .homeCnt .slick-prev {
        left: -45px;
    }

    .homeCnt .slick-next,
    .homeCnt .slick-prev {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .homeCnt {
        overflow-x: hidden;
    }
}

@media (max-width: 1200px) {
    .wlecomeNt p {
        font-size: 23px;
    }
    .homeCnt .primButton.large button {
        height: 54px;
    }

    .wlecomeNt.col-md-6 {
        flex: 0 0 35%;
        max-width: 35%;
    }

    .bnrCnt .col-md-6:last-child {
        flex: 0 0 65%;
        max-width: 65%;
    }

    .wlecomeNt h2 {
        font-size: 30px;
        line-height: 42px;
    }

    .wlecomeNt .lftCntnr {
        background: #293345;
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 60px;
        padding-right: 31px;
    }
}

@media (max-width: 992px) and (min-width: 991px) {
    /* .hiw1Txt , .hiw2Txt{
        padding-left: 5px;
    } */
    .Hiw1 img {
        padding-left: 0;
    }
    .howitsWrk {
        padding: 45px 90px;
    }
}
@media (max-width: 991px) {
    .wlecomeNt p {
        font-size: 24px;
    }
    .homeCnt .primButton {
        margin-top: auto;
    }
    .auctionHeading {
        font-size: 24px;
    }
    .wlecomeNt {
        color: #fff;
        z-index: 99;
    }
    .upcomingAuction {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .howitsWrk {
        padding: 45px 60px;
    }
    .wlecomeNt.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }

    .bnrCnt {
        height: unset;
    }

    .bnrCnt.row {
        margin-left: 0;
        margin-right: 0;
    }

    .bnrCnt .col-md-6:last-child {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }

    .bnrCnt {
        flex-wrap: wrap-reverse;
        padding: 0;
    }

    .bnrCnt .bnrImg {
        height: 380px;
    }
    .wlecomeNt p {
        margin-top: 10px;
        text-align: center;
    }

    .wlecomeNt .lftCntnr {
        padding: 20px;
    }

    .wlecomeNt h2 {
        text-align: center;
    }

    .homeCnt .primButton {
        width: 100%;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .homeCnt .primButton.large button {
        height: 50px;
    }

    .btmCtctUsWrpr {
        padding: 30px;
    }

    .btmCtctUsWrpr .primButton {
        margin: 0;
    }
}

@media (max-width: 940px) {
    .hiwGrid {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: 15px;
    }
}

@media (max-width: 900px) {
    .mobilMarginBotton {
        margin-bottom: 20px;
    }
    .category {
        display: block;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 770px) {
    .category {
        display: block !important;
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    }
}
@media (max-width: 780px) {
    .footerCnt img.Logo {
        width: 115px;
    }
    .adrsWrp img {
        object-fit: scale-down;
    }

    .hiwGrid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        grid-gap: 0px;
        gap: 0px;
    }

    .imgGlryWrpr .slick-slide img {
        height: 220px;
    }
}
@media (max-width: 640px) {
    .callUsLink {
        margin: auto;
    }

    .hiwGrid {
        grid-template-columns: 1fr 1fr;
    }

    .hiwGrid .hiwCard h5 {
        font-size: 18px;
    }

    .homeCnt .slick-next {
        right: -10px;
    }

    .homeCnt .slick-prev {
        left: -10px;
    }

    .homeCnt .slick-next,
    .homeCnt .slick-prev {
        height: 40px;
        width: 40px;
        background: white;
        border-radius: 100px;
        box-shadow: 0 0 10px #00000026;
        padding: 11px;
        z-index: 100;
    }

    .homeCnt .bnrCnt .slick-next,
    .homeCnt .bnrCnt .slick-prev {
        background: transparent;
        border-radius: 100px;
        box-shadow: unset;
        padding: 6px;
    }

    .imgGlryWrpr h4 {
        font-size: 27px;
    }

    .wlecomeNt h2 {
        font-size: 24px;
        line-height: 37px;
    }

    .wlecomeNt p {
        font-size: 19px;
    }

    .callUsLink {
        height: 44px;
    }

    .btmCtctUsWrpr {
        flex-wrap: wrap;
    }

    .btmCtctUsWrpr p {
        margin: 0;
        margin-bottom: 15px;
    }
}
@media (max-width: 500px) {
    .footerCnt {
        padding-bottom: 65px !important;
    }

    .imgGlryWrpr .slick-slide img {
        height: 140px;
        object-position: top;
    }
}

@media (max-width: 570px) {
    .category {
        padding: 30px 15px;
    }
}

@media (max-width: 475px) {
    .homeCnt .vrtlSldr .slick-next,
    .homeCnt .vrtlSldr .slick-prev {
        top: -40px;
    }
    .catHeading {
        font-size: 16px;
    }
    .wlecomeNt p br {
        display: none;
    }
    /* .wlecomeNt {
        padding-right: 25px;
    } */
    .hiw1Txt {
        padding-left: 15px;
    }
    .howitsWrk {
        padding: 150px 30px 30px 30px;
    }
    .hiw2Cnt img,
    .hiw3Cnt img,
    .hiw1Cnt img {
        padding-left: initial;
        width: 100%;
        max-width: 75%;
    }
    .hiw2Txt {
        padding-left: initial;
        padding-right: 10px;
    }
    .hiw3Txt {
        padding-left: 15px;
    }
    .homeCnt .vwAllBtn {
        font-size: 12px;
    }
    .homeCnt .lftpdWrp {
        padding-right: 0;
    }
}

@media (max-width: 425px) {
    .wlecomeNt p {
        font-size: 16px;
        line-height: 25px;
    }
    .callUsLink {
        width: auto;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .upcomingAuction {
        grid-template-columns: 1fr;
        padding-bottom: 25px;
        padding-top: 25px;
    }
}

@media (max-width: 380px) {
    .termCondt p {
        padding-left: 3px;
        padding-right: 3px;
        margin-top: 10px;
    }
    .upcomingAuction {
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
}

@media (max-width: 350px) {
    .termCondt {
        flex-wrap: wrap;
        justify-content: center;
        padding: 6px 0;
    }
    .termCondt p {
        margin-top: 3px;
        margin-bottom: 3px;
    }
}
